var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[_vm.$vuetify.breakpoint.mobile ? '' : 'card-bg-step-00'],attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.mobile}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"'rounded-0'",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"celeste"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"10"}},[_c('v-card',{staticClass:"rounded-0",attrs:{"flat":"","color":"azul2"}},[_c('v-card-text',{class:[
                        'text-left',
                        _vm.$vuetify.breakpoint.mobile ? '' : 'pa-12'
                      ]},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"10","offset-lg":"1"}},[_c('h1',{class:[
                                'turquesa--text pb-10',
                                _vm.$vuetify.breakpoint.mobile ? 'text-center pt-10' : ''
                              ],staticStyle:{"line-height":"normal"}},[_vm._v("TRANSPARENCIA + DESCENTRALIZACIÓN + ACCESIBILIDAD")]),_c('p',{staticClass:"chivo"},[_vm._v(" TOKENFIT es la primera experiencia de venta de un inmueble en Argentina utilizando íntegramente tecnología Blockchain. ")]),_c('p',{staticClass:"chivo"},[_vm._v(" Exploramos un camino nuevo, probando límites y necesidades del sistema jurídico y tecnológico Argentino. Contribuiremos con un correcto tratamiento legislativo y el desarrollo de nuevos mercados. ")])])],1)],1)],1)],1)],1),_c('v-col',{class:[
                      _vm.$vuetify.breakpoint.mobile ?
                        'azul2 pb-12' :
                        'text-left pa-0 ma-0 align-self-center celeste'
                    ],attrs:{"cols":"12","sm":"12","md":"12","lg":"2"}},[_c('span',[_c('inscribite-button',{attrs:{"left-flat":!_vm.$vuetify.breakpoint.mobile,"no-block":_vm.$vuetify.breakpoint.mobile}})],1),_c('span',{staticClass:"d-block mt-7"},[_c('mas-info-button',{attrs:{"left-flat":!_vm.$vuetify.breakpoint.mobile,"no-block":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){_vm.showMoreInfo = !_vm.showMoreInfo}}})],1)])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoreInfo),expression:"showMoreInfo"}],staticClass:"chivo auto-margin rounded-0 pt-10 pb-10 text-left",attrs:{"flat":"","color":"white","light":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('p',{staticClass:"font-weight-bold azul4--text"},[_vm._v(" TOKENFIT quiere impulsar la adopción de tecnología blockchain acelerando la conjunción del sector de Real Estate con los nuevos modelos de negocios basados en Web3. ")]),_c('p',[_vm._v(" En su primera etapa, TOKENFIT es una DApp (Decentralized Application) de desarrollo propio que permite realizar la venta de un departamento en subasta íntegramente con tecnología Blockchain y garantizando la legalidad del proceso mediante el uso de Smart Contracts y NFTs. ")]),_c('p',[_vm._v(" Esta experiencia contará de "),_c('b',[_vm._v("4 instancias principales, Digitalización, Inscripción, Subasta y Escrituración")]),_vm._v(", donde los interesados en adquirir el inmueble podrán realizar su pre-inscripción mediante nuestra DApp y participar de la subasta, el ganador accederá a la fase de final de escrituración y será titular registral del inmueble. ")])])],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('saber-mas-button',{attrs:{"title":"SABER MÁS","no-block":""}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }